$backgroundColor: #eafcff;
$primary: #75efb1;

$fontColor: #717e81;
$primaryFontColor: #4b82db;

$gap: 20px;
$mid-gap: 40px;
$large-gap: 140px;

$mobile-gap: 5px;
$mobile-mid-gap: 10px;
$mobile-large-gap: 40px;