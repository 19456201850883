@font-face {
    font-family: champagneBold;
    src: url(../assets/fonts/champagne-bold.ttf);
}

@font-face {
    font-family: champagne;
    src: url(../assets/fonts/champagne.ttf);
}

body,
html,
p,
h1,
h2,
h3,
h4,
h5,
li,
span {
    font-family: champagne;
}

html {
    font-size: 18px;
    color: $fontColor;
}

h1,
h2,
h3,
h4,
h5 {
    color: $primaryFontColor;
    margin: 0 0 0 0;
}

h1,
h2 {
    font-size: 3em;
    line-height: .9em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
    color: #6d6d6d;
    margin-bottom: 0;
    line-height: 1em;
}

p {
    letter-spacing: .035em;
    line-height: 1.7em;
}

@media (max-width: 700px) {
    html {
        font-size: 16px;
    }

    h1,
    h2 {
        font-size: 1.8em;
    }
}