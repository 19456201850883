@font-face {
  font-family: champagneBold;
  src: url("champagne-bold.a135845b.ttf");
}

@font-face {
  font-family: champagne;
  src: url("champagne.e994fa55.ttf");
}

body, html, p, h1, h2, h3, h4, h5, li, span {
  font-family: champagne;
}

html {
  color: #717e81;
  font-size: 18px;
}

h1, h2, h3, h4, h5 {
  color: #4b82db;
  margin: 0;
}

h1, h2 {
  font-size: 3em;
  line-height: .9em;
}

h2 {
  font-size: 2em;
}

h3 {
  color: #6d6d6d;
  margin-bottom: 0;
  font-size: 1.5em;
  line-height: 1em;
}

p {
  letter-spacing: .035em;
  line-height: 1.7em;
}

@media (max-width: 700px) {
  html {
    font-size: 16px;
  }

  h1, h2 {
    font-size: 1.8em;
  }
}

.btn {
  color: #fff;
  letter-spacing: .06em;
  white-space: nowrap;
  background: #4b82db;
  border-radius: 60px;
  padding: 12px 24px;
  font-size: 1.2em;
  line-height: 1em;
  text-decoration: none;
  transition: all .2s ease-in;
}

.btn:hover {
  filter: brightness(1.1);
}

@media (max-width: 700px) {
  .btn {
    font-size: 1em;
  }
}

.btn-text {
  color: #4b82db;
  letter-spacing: .06em;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 60px;
  padding: 6px 14px;
  font-size: 1em;
  line-height: 1em;
  text-decoration: none;
  transition: all .2s ease-in;
  position: relative;
  overflow: hidden;
}

.btn-text:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  background: #4b82db;
  transition: all .2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-text:hover:before {
  filter: brightness(1.1);
  opacity: .1;
}

@media (max-width: 700px) {
  .btn-text {
    font-size: 1em;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

::-webkit-scrollbar-button {
  width: 3px;
  height: 3px;
}

@media (max-width: 900px) {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}

::-webkit-scrollbar-thumb {
  background: #4b82db;
  border: none;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4b82db;
}

::-webkit-scrollbar-thumb:active {
  background: #4b82db;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 3px;
}

::-webkit-scrollbar-track:hover {
  background: none;
}

::-webkit-scrollbar-track:active {
  background: none;
}

::-webkit-scrollbar-corner {
  background: none;
}

html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  max-height: 100vh;
  -webkit-user-select: none;
  user-select: none;
  background: #eafcff;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  overflow: hidden;
}

html .loadingModal {
  height: 100vh;
  width: 100vw;
  z-index: 5000;
  opacity: 0;
  pointer-events: none;
  background: #75efb1;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

html[loading] .loadingModal {
  opacity: 1;
  transition: all;
}

html .loader {
  color: #fff;
  text-indent: -9999em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  font-size: 90px;
  animation: load6 1.7s infinite, round 1.7s infinite;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.lazyLoad {
  opacity: 0;
  animation-delay: .5s !important;
}

.lazyLoad.active.lazy-up {
  animation: furnitureUp .2s forwards;
}

.lazyLoad.active.lazy-down {
  animation: furnitureDown .2s forwards;
}

.websiteMenu {
  grid-gap: 20px;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 1100px) {
  .websiteMenu {
    display: none;
  }
}

.websiteMenu li {
  opacity: 0;
  animation: fadeUp .12s ease-in forwards;
}

.websiteMenu li:nth-child(0) {
  animation-delay: 0s;
}

.websiteMenu li:nth-child(1) {
  animation-delay: 50ms;
}

.websiteMenu li:nth-child(2) {
  animation-delay: .1s;
}

.websiteMenu li:nth-child(3) {
  animation-delay: .15s;
}

.websiteMenu li:nth-child(4) {
  animation-delay: .2s;
}

.websiteMenu li:nth-child(5) {
  animation-delay: .25s;
}

.websiteMenu li:nth-child(6) {
  animation-delay: .3s;
}

.websiteMenu li:nth-child(7) {
  animation-delay: .35s;
}

.websiteMenu li:nth-child(8) {
  animation-delay: .4s;
}

.websiteMenu li:nth-child(9) {
  animation-delay: .45s;
}

.websiteMenu li:nth-child(10) {
  animation-delay: .5s;
}

.websiteMenu a {
  color: #4b82db;
  padding: 0 5px 5px;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}

.websiteMenu a:before {
  content: "";
  height: 2px;
  width: 100%;
  opacity: 0;
  background: #4b82db;
  transition: all .2s ease-in;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -5px);
}

.websiteMenu a:hover:before {
  opacity: 1;
  transform: translate(-50%);
}

.wrapBanner {
  height: 95vh;
  overflow: hidden;
}

@media (max-width: 649px) {
  .wrapBanner {
    height: 80vh;
  }
}

@media (max-width: 500px) {
  .wrapBanner {
    height: calc(54vh + 40px);
  }
}

#mainBanner {
  min-height: 75vh;
  width: 100%;
  background: #75efb1;
  position: relative;
}

@media (max-width: 992px) {
  #mainBanner {
    min-height: 70vh;
  }
}

@media (max-width: 650px) {
  #mainBanner {
    min-height: 50vh;
  }
}

@media (max-width: 500px) {
  #mainBanner {
    min-height: 40vh;
  }
}

#mainBanner .logo {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 20px;
  left: 20px;
}

@media (max-width: 550px) {
  #mainBanner .logo {
    width: 90px;
    height: 90px;
    top: 10px;
    left: 10px;
  }
}

#mainBanner .bannerElement {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#mainBanner .bannerElement img {
  width: 100%;
  height: auto;
}

#mainBanner .elements > figure {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#mainBanner .elements > figure img {
  max-width: 100%;
}

#mainBanner .elements .lamp {
  z-index: 2;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 992px) {
  #mainBanner .elements .lamp {
    width: 70px;
  }
}

@media (max-width: 500px) {
  #mainBanner .elements .lamp {
    width: 50px;
  }
}

#mainBanner .elements .plant {
  bottom: -80px;
  top: unset;
  right: 0;
  left: unset;
  z-index: 3;
}

@media (max-width: 992px) {
  #mainBanner .elements .plant {
    display: none;
  }
}

#mainBanner .elements .sofa {
  top: unset;
  z-index: 1;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 800px) {
  #mainBanner .elements .sofa {
    width: 750px;
  }
}

@media (max-width: 799px) {
  #mainBanner .elements .sofa {
    max-width: calc(100vw - 40px);
    width: 100%;
    padding: 0 20px;
  }
}

#successCases ul {
  grid-gap: 4px;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
  padding: 0 10px;
  list-style: none;
  display: flex;
}

#successCases ul li {
  min-height: 400px;
  flex: 1;
  display: flex;
  overflow: hidden;
}

@media (max-width: 900px) {
  #successCases ul li {
    min-height: unset !important;
  }
}

#successCases ul li figure {
  margin: 0;
  padding: 0;
  position: relative;
}

#successCases ul li figure img {
  width: 100%;
  height: auto;
  opacity: 1;
  transition: all .3s ease-in;
}

#successCases ul li figure img:last-child {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#successCases ul li figure img[active] {
  opacity: 1;
}

#successCases ul li:first-child {
  border-radius: 30px 0 0 30px;
}

#successCases ul li:last-child {
  border-radius: 0 30px 30px 0;
}

#successCases ul li:nth-child(2) img {
  transition-delay: .15s;
}

#successCases ul li:nth-child(3) img {
  transition-delay: .3s;
}

@media (max-width: 800px) {
  #successCases ul li {
    border-radius: 10px !important;
  }
}

.firstCTA, .guarantee {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  display: flex;
}

.firstCTA.guarantee, .guarantee.guarantee {
  margin: 30px 0 70px;
}

.firstCTA h1, .guarantee h1 {
  padding: 0 20px;
}

.firstCTA p, .guarantee p {
  max-width: 1000px;
  padding: 0 20px;
}

.firstCTA .controllers, .guarantee .controllers {
  grid-gap: 10px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

@media (max-width: 700px) {
  .firstCTA, .guarantee {
    margin-bottom: 40px;
  }

  .firstCTA.guarantee, .guarantee.guarantee {
    margin: 30px 0 40px;
  }
}

.servicesGallery {
  overflow: hidden;
}

.servicesGallery ul {
  grid-gap: 0;
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

.servicesGallery ul li img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

@media (min-width: 992px) and (max-width: 1100px) {
  .servicesGallery ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .servicesGallery ul li {
    flex-direction: column;
    display: flex;
    position: relative;
  }

  .servicesGallery ul li:before, .servicesGallery ul li:after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all .18s ease-in;
    position: absolute;
    top: 0;
    left: 0;
  }

  .servicesGallery ul li:before {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  .servicesGallery ul li:after {
    background: #000;
  }

  .servicesGallery ul li .imageLabel {
    opacity: 0;
    width: calc(100% - 20px);
    z-index: 1;
    transition: all .12s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .servicesGallery ul li .imageLabel h3, .servicesGallery ul li .imageLabel {
    color: #fff;
    text-align: center;
    letter-spacing: .065em;
  }

  .servicesGallery ul li .imageLabel p {
    margin: 8px 0;
    line-height: 1.2em;
  }

  .servicesGallery ul li .imageLabel h3 {
    font-size: 1.3em;
  }

  .servicesGallery ul li:hover:before {
    opacity: 1;
  }

  .servicesGallery ul li:hover:after {
    opacity: .3;
  }

  .servicesGallery ul li:hover .imageLabel {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .servicesGallery ul {
    grid-gap: 10px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px 40px;
  }

  .servicesGallery ul li img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  .servicesGallery ul li h3 {
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
  }

  .servicesGallery ul li p {
    display: none;
  }

  .servicesGallery ul li {
    flex-direction: column-reverse;
    justify-content: flex-end;
    display: flex;
  }
}

.bulletPoints {
  color: #fff;
  letter-spacing: .06em;
  background: #4b82db;
  justify-content: center;
  margin-bottom: 40px;
  padding: 40px 0;
  display: flex;
}

@media (max-width: 700px) {
  .bulletPoints .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .bulletPoints .row > h2, .bulletPoints .row > p {
    text-align: center;
    width: 100%;
  }
}

.bulletPoints h2, .bulletPoints h3 {
  color: #fff;
  letter-spacing: .06em;
}

.bulletPoints ul {
  max-width: 70vw;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (max-width: 900px) {
  .bulletPoints ul {
    max-width: calc(100vw - 20px);
  }
}

@media (max-width: 900px) and (max-width: 600px) {
  .bulletPoints ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

.bulletPoints ul li {
  grid-gap: 10px;
  flex-direction: row;
  display: flex;
}

.bulletPoints ul li i svg {
  fill: #fff;
  height: 40px;
}

@media (max-width: 700px) {
  .bulletPoints ul li i svg {
    height: 30px;
  }
}

.bulletPoints ul li h3 {
  margin-bottom: 5px;
  font-size: 1.2em;
  line-height: 1.2em;
}

.bulletPoints ul li p {
  letter-spacing: .06em;
  letter-spacing: .06em;
  margin: 0;
  line-height: 1.2em;
}

.whatsappFloatingButton {
  z-index: 1000;
  animation: bounceUpDown .9s ease-in infinite forwards;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.whatsappFloatingButton img {
  width: 60px;
  height: auto;
}

@media (max-width: 900px) {
  .whatsappFloatingButton img {
    width: 40px;
  }
}

.containSlider {
  min-height: 100vh;
  place-items: center;
  padding: 0 10px;
  display: grid;
}

@media (max-width: 500px) {
  .containSlider {
    min-height: 40vh;
  }
}

.containSlider #before-after-slider {
  border-radius: 130px 30px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 700px) {
  .containSlider #before-after-slider {
    border-radius: 20px 10px;
  }
}

.containSlider #before-after-slider img {
  max-width: 100%;
  display: block;
}

.containSlider #before-after-slider .container {
  --position: 50%;
  border-radius: 1rem;
  place-content: center;
  display: grid;
  position: relative;
  overflow: hidden;
}

.containSlider #before-after-slider .image-container {
  max-width: 100vw;
  max-height: 90vh;
  aspect-ratio: 16 / 9;
}

@media (max-width: 700px) {
  .containSlider #before-after-slider .image-container {
    aspect-ratio: 14 / 9;
  }
}

.containSlider #before-after-slider .slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: -90px;
}

@media (max-width: 800px) {
  .containSlider #before-after-slider .slider-image {
    object-position: -50px;
  }
}

@media (max-width: 500px) {
  .containSlider #before-after-slider .slider-image {
    object-position: -50px;
  }
}

.containSlider #before-after-slider .image-before {
  width: var(--position);
  position: absolute;
  inset: 0;
}

.containSlider #before-after-slider .slider {
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.containSlider #before-after-slider .slider:focus-visible ~ .slider-button {
  outline-offset: 3px;
  outline: 5px solid #000;
}

.containSlider #before-after-slider .slider-line {
  width: .2rem;
  height: 100%;
  inset: 0;
  left: var(--position);
  pointer-events: none;
  background-color: #4b82db;
  position: absolute;
  transform: translateX(-50%);
}

.containSlider #before-after-slider .slider-button {
  color: #fff;
  top: 50%;
  left: var(--position);
  pointer-events: none;
  background-color: #4b82db;
  border-radius: 100vw;
  place-items: center;
  padding: .5rem;
  display: grid;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 1px #08030380;
}

#reviews {
  padding: 140px 0 20px;
  position: relative;
}

@media (max-width: 650px) {
  #reviews {
    padding: 40px;
  }
}

#reviews h2 {
  text-align: center;
  margin-bottom: 40px;
}

#reviews span {
  color: #4b82db;
  color: #6d6d6d;
  margin: 0;
  font-size: 1.5em;
  line-height: 1em;
}

#reviews .reviewSlideShow {
  grid-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#reviews .reviewSlideShow .rating {
  grid-gap: 4px;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

#reviews .reviewSlideShow .rating li {
  text-shadow: 0 0 1px #385044;
}

#reviews .reviewItem {
  max-width: 600px;
  opacity: 0;
  grid-gap: 20px;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  padding: 40px 20px;
  transition: all .2s ease-in;
  animation: fadeUp .2s ease-in forwards;
  display: none;
  box-shadow: 0 0 24px -20px #000;
}

@media (max-width: 650px) {
  #reviews .reviewItem {
    max-width: calc(100vw - 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  #reviews .reviewItem span, #reviews .reviewItem p {
    text-align: center;
  }
}

#reviews .reviewItem[active] {
  opacity: 1;
  display: flex;
}

#reviews .reviewItem .reviewAvatar img {
  border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 650px) {
  #reviews .reviewItem .reviewAvatar img {
    max-width: 80px;
  }
}

#reviews .reviewItem p {
  margin: 0;
  padding: 20px 30px;
}

@media (max-width: 650px) {
  #reviews .reviewItem p {
    padding: 5px;
  }
}

#reviews .footerElement {
  max-height: 75vh;
  width: 100%;
  z-index: -1;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

#reviews .footerElement img {
  width: 100%;
  height: auto;
}

#modal {
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#modal .blackScreen {
  content: "";
  width: 100vw;
  height: 100vh;
  background-color: #0000004d;
  animation: fadeUp .4s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

#modal .blackScreen:active ~ .wrapContent .innerContent {
  transform: scale(.95);
}

#modal:not(.show) {
  display: none;
}

#modal:not(.show):before {
  background-color: #0000;
}

#modal > .wrapContent {
  z-index: 10;
  max-width: 650px;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  animation: fadeUp .4s ease-in forwards;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 600px) {
  #modal > .wrapContent {
    max-width: calc(100vw - 20px);
  }
}

#modal > .wrapContent .innerContent {
  padding: 20px;
  transition: all .1s ease-in;
}

#modal > .wrapContent .innerContent h2 {
  text-align: center;
  font-size: 1.8em;
}

#modal > .wrapContent .innerContent p {
  text-align: center;
  margin-bottom: 20px;
}

#modal > .wrapContent .innerContent p .btn {
  padding: 6px 12px;
  font-size: 1.1em;
}

#modal > .wrapContent .modalHeader {
  height: 160px;
  background-image: url("limpeza-estofados-profissional.b234e82a.jpg");
  background-position: center;
  background-size: cover;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px;
  display: flex;
}

@media (max-width: 600px) {
  #modal > .wrapContent .modalHeader {
    height: 90px;
  }
}

#modal > .wrapContent .modalHeader svg {
  color: #fff;
  cursor: pointer;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes furnitureUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  33% {
    opacity: 1;
    transform: translateY(-40px);
  }

  66% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes furnitureDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -60px);
  }

  33% {
    opacity: 1;
    transform: translate(-50%, 40px);
  }

  66% {
    opacity: 1;
    transform: translate(-50%, -20px);
  }

  100% {
    opacity: 1;
    transform: translate(-50%);
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }

  5%, 95% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }

  10%, 59% {
    box-shadow: 0 -.83em 0 -.4em, -.087em -.825em 0 -.42em, -.173em -.812em 0 -.44em, -.256em -.789em 0 -.46em, -.297em -.775em 0 -.477em;
  }

  20% {
    box-shadow: 0 -.83em 0 -.4em, -.338em -.758em 0 -.42em, -.555em -.617em 0 -.44em, -.671em -.488em 0 -.46em, -.749em -.34em 0 -.477em;
  }

  38% {
    box-shadow: 0 -.83em 0 -.4em, -.377em -.74em 0 -.42em, -.645em -.522em 0 -.44em, -.775em -.297em 0 -.46em, -.82em -.09em 0 -.477em;
  }

  100% {
    box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(0);
  }

  80%, 90% {
    transform: translateY(3px);
  }

  85%, 95% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

/*# sourceMappingURL=index.1b5d3cff.css.map */
