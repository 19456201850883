.btn {
    background: $primaryFontColor;
    padding: 12px 24px;
    line-height: 1em;
    color: #fff;
    text-decoration: none;
    border-radius: 60px;
    font-size: 1.2em;
    letter-spacing: .06em;
    transition: all 200ms ease-in;
    white-space: nowrap;

    &:hover {
        filter: brightness(1.1);
    }

    @media (max-width: 700px) {
        font-size: 1em;
    }
}

.btn-text {
    padding: 6px 14px;
    line-height: 1em;
    color: $primaryFontColor;
    text-decoration: none;
    font-size: 1em;
    letter-spacing: .06em;
    transition: all 200ms ease-in;
    border-radius: 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        pointer-events: none;
        background: $primaryFontColor;
        left: 0;
        top: 0;
        transition: all 200ms ease-in;
        opacity: 0;
    }

    &:hover {
        &:before {
            filter: brightness(1.1);
            opacity: .1;
        }
    }

    @media (max-width: 700px) {
        font-size: 1em;
    }
}