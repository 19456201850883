@import "./variables.scss";
@import "./fonts.scss";
@import "./button.scss";
@import "./scrollbar.scss";

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: $backgroundColor;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    user-select: none;
}

html {
    overflow: hidden;

    .loadingModal {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        z-index: 5000;
        transition: all 200ms ease-in;
        opacity: 0;
        background: $primary;
        pointer-events: none;
    }

    &[loading] {
        .loadingModal {
            transition: all 0ms;
            opacity: 1;
        }
    }

    .loader {
        color: #ffffff;
        font-size: 90px;
        text-indent: -9999em;
        overflow: hidden;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        margin: 72px auto;
        position: relative;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    }
}

.lazyLoad {
    opacity: 0;
    animation-delay: 500ms !important;

    &.active.lazy-up {
        animation: furnitureUp 200ms;
        animation-fill-mode: forwards;
    }

    &.active.lazy-down {
        animation: furnitureDown 200ms;
        animation-fill-mode: forwards;
    }
}

.websiteMenu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: absolute;
    right: $gap;
    top: $gap;
    grid-gap: $gap;

    @media (max-width: 1100px) {
        & {
            display: none;
        }
    }

    li {
        opacity: 0;
        animation: fadeUp 120ms ease-in;
        animation-fill-mode: forwards;

        @for $i from 0 through 10 {
            &:nth-child(#{$i}) {
                animation-delay: calc(#{$i} * 50ms);
            }
        }
    }

    a {
        font-weight: bold;
        color: $primaryFontColor;
        text-decoration: none;
        padding: 0 5px 5px 5px;
        position: relative;

        &:before {
            content: "";
            height: 2px;
            background: $primaryFontColor;
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -5px);
            bottom: 0;
            transition: all 200ms ease-in;
            opacity: 0;
        }

        &:hover {
            &:before {
                transform: translate(-50%, 0px);
                opacity: 1;
            }
        }
    }
}

.wrapBanner {
    overflow: hidden;
    height: 95vh;

    @media (max-width: 649px) {
        height: 80vh;
    }

    @media (max-width: 500px) {
        height: calc(54vh + $mobile-large-gap);
    }
}

#mainBanner {
    background: $primary;
    min-height: 75vh;
    width: 100%;
    position: relative;

    @media (max-width: 992px) {
        min-height: 70vh;
    }

    @media (max-width: 650px) {
        min-height: 50vh;
    }

    @media (max-width: 500px) {
        min-height: 40vh;
    }

    .logo {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 120px;
        height: 120px;

        @media (max-width: 550px) {
            width: 90px;
            height: 90px;
            left: 10px;
            top: 10px;
        }
    }

    .bannerElement {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .elements {
        &>figure {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;

            img {
                max-width: 100%;
            }
        }

        .lamp {
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            width: 100px;

            @media (max-width: 992px) {
                width: 70px;
            }

            @media (max-width: 500px) {
                width: 50px;
            }
        }

        .plant {
            bottom: -80px;
            top: unset;
            right: 0;
            left: unset;
            z-index: 3;

            @media (max-width: 992px) {
                display: none;
            }
        }

        .sofa {
            top: unset;
            bottom: -90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;

            @media (min-width: 800px) {
                width: 750px
            }

            @media (max-width: 799px) {
                max-width: calc(100vw - $mid-gap);
                padding: 0 20px;
                width: 100%;
            }
        }
    }
}

#successCases {
    ul {
        margin: 0;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        list-style: none;
        grid-gap: 4px;

        li {
            display: flex;
            flex: 1;
            min-height: 400px;
            overflow: hidden;

            @media (max-width: 900px) {
                & {
                    min-height: unset !important;
                }
            }

            figure {
                position: relative;
                padding: 0;
                margin: 0;

                img {
                    width: 100%;
                    height: auto;
                    opacity: 1;
                    transition: all 300ms ease-in;

                    &:last-child {
                        position: absolute;
                        left: 0;
                        top: 0;
                        pointer-events: none;
                        opacity: 0;
                    }

                    &[active] {
                        opacity: 1;
                    }
                }
            }

            &:first-child {
                border-radius: 30px 0 0 30px;
            }

            &:last-child {
                border-radius: 0 30px 30px 0;
            }

            &:nth-child(2) {
                img {
                    transition-delay: 150ms;
                }
            }

            &:nth-child(3) {
                img {
                    transition-delay: 300ms;
                }
            }

            @media (max-width: 800px) {
                & {
                    border-radius: 10px !important;
                }
            }
        }
    }
}

.firstCTA,
.guarantee {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 70px;

    &.guarantee {
        margin: 30px 0 70px 0;
    }

    h1 {
        padding: 0 20px;
    }

    p {
        max-width: 1000px;
        padding: 0 20px;
    }

    .controllers {
        display: flex;
        flex-direction: row;
        grid-gap: 10px;
        align-items: center;
    }

    @media (max-width: 700px) {
        margin-bottom: $mobile-large-gap;
    }

    @media (max-width: 700px) {
        &.guarantee {
            margin: 30px 0 40px 0;
        }
    }
}

.servicesGallery {
    overflow: hidden;

    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            img {
                height: 100%;
                width: auto;
                max-width: 100%;
            }
        }

        @media (min-width: 992px) {
            @media (max-width: 1100px) {
                grid-template-columns: repeat(2, 1fr);
            }

            li {
                position: relative;
                display: flex;
                flex-direction: column;

                &:before,
                &:after {
                    content: "";
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 180ms ease-in;
                }

                &:before {
                    backdrop-filter: blur(3px);
                }

                &:after {
                    background: #000;
                }

                .imageLabel {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: all 120ms ease-in;
                    width: calc(100% - 20px);
                    z-index: 1;

                    h3,
                    & {
                        color: #fff;
                        text-align: center;
                        letter-spacing: .065em;
                    }

                    p {
                        line-height: 1.2em;
                        margin: 8px 0;
                    }

                    h3 {
                        font-size: 1.3em;
                    }
                }

                &:hover {

                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        opacity: .3;
                    }

                    .imageLabel {
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: 991px) {
            & {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                padding: 0 $gap $mid-gap $gap;
                grid-row-gap: $gap;

                li {
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 30px;
                    }

                    h3 {
                        font-size: 20px;
                        padding-top: calc($gap / 2);
                        text-align: center;
                    }

                    p {
                        display: none;
                    }
                }
            }


            li {
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        }
    }
}

.bulletPoints {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    background: #4b82db;
    color: #fff;
    letter-spacing: .06em;
    margin-bottom: 40px;

    .row {
        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &>h2,
            &>p {
                text-align: center;
                width: 100%;
            }
        }
    }

    h2,
    h3 {
        color: #fff;
        letter-spacing: .06em;
    }

    ul {
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 70vw;
        grid-gap: 20px;
        list-style: none;

        @media (max-width: 900px) {
            max-width: calc(100vw - 20px);

            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        li {
            display: flex;
            flex-direction: row;
            grid-gap: 10px;

            i {
                svg {
                    fill: #fff;
                    height: 40px;

                    @media (max-width: 700px) {
                        height: 30px;
                    }
                }
            }

            h3 {
                font-size: 1.2em;
                margin-bottom: 5px;
                line-height: 1.2em;
            }

            p {
                line-height: 1.2em;
                margin: 0;
                letter-spacing: .06em;
                letter-spacing: .06em;
            }
        }
    }
}

.whatsappFloatingButton {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1000;
    animation: bounceUpDown 900ms ease-in infinite;
    animation-fill-mode: forwards;

    img {
        width: 60px;
        height: auto;

        @media (max-width: 900px) {
            width: 40px;
        }
    }
}

.containSlider {
    display: grid;
    place-items: center;
    min-height: 100vh;
    padding: 0 10px;

    @media (max-width: 500px) {
        min-height: 40vh;
    }

    #before-after-slider {
        position: relative;
        border-radius: 130px 30px;
        overflow: hidden;

        @media (max-width: 700px) {
            border-radius: 20px 10px;
        }

        img {
            display: block;
            max-width: 100%;
        }

        .container {
            display: grid;
            place-content: center;
            position: relative;
            overflow: hidden;
            border-radius: 1rem;
            --position: 50%;
        }

        .image-container {
            max-width: 100vw;
            max-height: 90vh;
            aspect-ratio: 16/9;

            @media (max-width: 700px) {
                aspect-ratio: 14/9;
            }
        }

        .slider-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: -90px;

            @media (max-width: 800px) {
                object-position: -50px;
            }

            @media (max-width: 500px) {
                object-position: -50px;
            }
        }

        .image-before {
            position: absolute;
            inset: 0;
            width: var(--position);
        }

        .slider {
            position: absolute;
            inset: 0;
            cursor: pointer;
            opacity: 0;
            width: 100%;
            height: 100%;
        }

        .slider:focus-visible~.slider-button {
            outline: 5px solid black;
            outline-offset: 3px;
        }

        .slider-line {
            position: absolute;
            inset: 0;
            width: .2rem;
            height: 100%;
            background-color: $primaryFontColor;
            left: var(--position);
            transform: translateX(-50%);
            pointer-events: none;
        }

        .slider-button {
            position: absolute;
            background-color: $primaryFontColor;
            color: #fff;
            padding: .5rem;
            border-radius: 100vw;
            display: grid;
            place-items: center;
            top: 50%;
            left: var(--position);
            transform: translate(-50%, -50%);
            pointer-events: none;
            box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
        }
    }
}

#reviews {
    padding: $large-gap 0 20px;
    position: relative;

    @media (max-width: 650px) {
        padding: $mobile-large-gap;
    }

    h2 {
        text-align: center;
        margin-bottom: $mid-gap;
    }

    span {
        color: $primaryFontColor;
        margin: 0 0 0 0;
        font-size: 1.5em;
        color: #6d6d6d;
        margin-bottom: 0;
        line-height: 1em;
    }

    .reviewSlideShow {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        grid-gap: 8px;

        .rating {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            align-items: center;
            grid-gap: 4px;
            list-style: none;

            li {
                text-shadow: 0 0 1px #385044;
            }
        }
    }

    .reviewItem {
        padding: 40px 20px;
        background: #fff;
        max-width: 600px;
        border-radius: 20px;
        box-shadow: 0 0 24px -20px #000;
        transition: all 200ms ease-in;
        opacity: 0;
        display: none;
        flex-direction: row;
        grid-gap: 20px;
        animation: fadeUp 200ms ease-in;
        animation-fill-mode: forwards;

        @media (max-width: 650px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            max-width: calc(100vw - 40px);

            span,
            p {
                text-align: center;
            }
        }

        &[active] {
            opacity: 1;
            display: flex;
        }

        .reviewAvatar {
            img {
                border-radius: 50%;
                overflow: hidden;
            }

            @media (max-width: 650px) {
                img {
                    max-width: 80px;
                }
            }
        }

        p {
            margin: 0;
            padding: 20px 30px;

            @media (max-width: 650px) {
                padding: 5px;
            }
        }
    }

    .footerElement {
        position: absolute;
        right: 0;
        max-height: 75vh;
        display: block;
        width: 100%;
        bottom: 0;
        z-index: -1;

        img {
            width: 100%;
            height: auto;
        }
    }
}

#modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 100;

    .blackScreen {
        content: "";
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .3);
        position: absolute;
        animation: fadeUp 400ms ease-in 1;
        left: 0;
        top: 0;

        &:active {
            &~.wrapContent .innerContent {
                transform: scale(.95);
            }
        }
    }

    &:not(.show) {
        display: none;

        &:before {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    &>.wrapContent {
        position: relative;
        display: flex;
        z-index: 10;
        flex-direction: column;
        background: #fff;
        max-width: 650px;
        border-radius: 10px;
        overflow: hidden;
        animation: fadeUp 400ms ease-in 1;
        animation-fill-mode: forwards;

        @media (max-width: 600px) {
            & {
                max-width: calc(100vw - 20px);
            }
        }

        .innerContent {
            padding: $gap;
            transition: all 100ms ease-in;

            h2 {
                font-size: 1.8em;
                text-align: center;
            }

            p {
                margin-bottom: 20px;
                text-align: center;

                .btn {
                    padding: 6px 12px;
                    font-size: 1.1em;
                }
            }
        }

        .modalHeader {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 8px;
            height: 160px;
            background-image: url(../assets/modal/limpeza-estofados-profissional.jpg);
            background-size: cover;
            background-position: center center;

            @media (max-width: 600px) {
                & {
                    height: 90px;
                }
            }

            svg {
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(8px)
    }

    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes furnitureUp {
    0% {
        opacity: 0;
        transform: translateY(60px)
    }

    33% {
        opacity: 1;
        transform: translateY(-40px)
    }

    66% {
        opacity: 1;
        transform: translateY(20px)
    }

    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes furnitureDown {
    0% {
        opacity: 0;
        transform: translate(-50%, -60px)
    }

    33% {
        opacity: 1;
        transform: translate(-50%, 40px)
    }

    66% {
        opacity: 1;
        transform: translate(-50%, -20px)
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0px)
    }
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes bounceUpDown {
    0% {
        transform: translateY(0)
    }

    80%,
    90% {
        transform: translateY(3px)
    }

    85%,
    95% {
        transform: translateY(-3px)
    }

    100% {
        transform: translateY(0)
    }
}