$thumbColor: $primaryFontColor;
$thumbHover: $primaryFontColor;
$thumbActive: $primaryFontColor;

::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

::-webkit-scrollbar-button {
    width: 3px;
    height: 3px;
}

@media (max-width: 900px) {
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
}

::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border: none;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: $thumbHover;
}

::-webkit-scrollbar-thumb:active {
    background: $thumbActive;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: none;
    border-radius: 3px;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}